var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-loading-dialog',{attrs:{"loading":_vm.$apollo.loading}},[_c('base-page-layout',{attrs:{"title":"Listagem de conexões"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mb-n6",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"label":"Configuração storage","icon":"settings"},on:{"click":function($event){_vm.dialog = true}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"label":"Criar nova conexão","icon":"add"},on:{"click":_vm.goToCreate}})],1)],1)],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('mf-card-container',{attrs:{"title":"Conexões Friday","description":'Listagem de todas as conexões vinculadas a conta ' + _vm.name},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.list_connections,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.updated_at ? _vm.formatDate(item.updated_at) : '-'))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":_vm.statusCon(item)}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(_vm.textCon(item))+" ")],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.goToDetails(item._id)}}},[_c('v-list-item-title',[_vm._v("Ver detalhes")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.changeStatusConnection(item._id, item.active)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.active ? 'Invativar Conexão' : 'Ativar Conexão'))])],1)],1)],1)]}}],null,true)})],1)],1)],1)],1),(_vm.dialog)?_c('dialog-storage',{attrs:{"gcs":_vm.jsonGcs},on:{"click-button":_vm.executeAction},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }