<template>
  <mf-loading-dialog :loading="$apollo.loading">
    <base-page-layout title="Listagem de conexões">
      <v-container fluid>
        <v-row>
          <v-col class="mb-n6" cols="12">
            <v-row justify="end">
              <v-col cols="auto">
                <mf-button label="Configuração storage" icon="settings" @click="dialog = true"></mf-button>
              </v-col>
              <v-col cols="auto">
                <mf-button label="Criar nova conexão" icon="add" @click="goToCreate"></mf-button>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0">
            <mf-card-container v-model="search" title="Conexões Friday" :description="'Listagem de todas as conexões vinculadas a conta ' + name">
              <v-data-table
                :search="search"
                :headers="headers"
                :items="list_connections"
                :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
                :options.sync="options"
              >
                <template v-slot:[`item.created_at`]="{ item }">
                  <span>{{ formatDate(item.created_at) }}</span>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <span>{{ item.updated_at ? formatDate(item.updated_at) : '-' }}</span>
                </template>
                <template v-slot:[`item.active`]="{ item }">
                  <v-flex>
                    <v-layout> <v-icon x-small class="mr-1" :color="statusCon(item)">mdi-brightness-1</v-icon>{{ textCon(item) }} </v-layout>
                  </v-flex>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu :close-on-content-click="false">
                    <template #activator="{ on }">
                      <v-icon v-on="on">more_vert</v-icon>
                    </template>
                    <v-list>
                      <v-list-item @click="goToDetails(item._id)">
                        <v-list-item-title>Ver detalhes</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="changeStatusConnection(item._id, item.active)">
                        <v-list-item-title>{{ item.active ? 'Invativar Conexão' : 'Ativar Conexão' }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </mf-card-container>
          </v-col>
        </v-row>
      </v-container>
      <dialog-storage v-if="dialog" v-model="dialog" :gcs="jsonGcs" @click-button="executeAction"></dialog-storage>
    </base-page-layout>
  </mf-loading-dialog>
</template>

<script>
import {
  QUERY_GET_CONNECTIONS_PAGINATED,
  MUTATION_CHANGE_STATUS_CONNECTION,
  QUERY_GET_STORAGE_CONFIG,
  MUTATION_CREATE_STORAGE,
  MUTATION_UPDATE_STORAGE
} from '../../graphql/friday'
import { QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'
import { DateTime } from 'luxon'
import DialogStorage from './DialogStorage.vue'
import BasePageLayout from '../../../../components/base/BasePageLayout.vue'
export default {
  components: {
    BasePageLayout,
    DialogStorage
  },
  data: () => ({
    search: '',
    accountId: '',
    list_connections: [],
    headers: [
      {
        text: 'machine id',
        value: 'machine_id'
      },
      {
        text: 'instance id',
        value: 'instance_id'
      },
      {
        text: 'Status',
        value: 'active'
      },
      {
        text: 'Data de criação',
        value: 'created_at'
      },
      {
        text: 'Data de atualização',
        value: 'updated_at'
      },
      {
        value: 'actions'
      }
    ],
    options: {},
    name: '',
    dialog: false,
    jsonGcs: null,
    edit: false
  }),
  computed: {
    context() {
      return {
        uri: this.$microservicesUrls['friday'],
        headers: {
          authorization: localStorage.getItem('session_id'),
          accountId: this.accountId
        }
      }
    }
  },
  apollo: {
    connections: {
      query: QUERY_GET_CONNECTIONS_PAGINATED,
      fetchPolicy: 'no-cache',
      variables() {
        return Object.assign(
          {},
          {
            filters: {
              account_id: this.accountId
            }
          },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      skip() {
        return !this.accountId
      },
      update(result) {
        this.list_connections = result.fridayConnections.data
      },
      context() {
        return this.context
      }
    },
    storage: {
      query: QUERY_GET_STORAGE_CONFIG,
      fetchPolicy: 'no-cache',
      variables() {
        return { accountId: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ getStorageConfig: data }) {
        this.jsonGcs = data.gcs
        this.edit = this.jsonGcs !== null
      },
      context() {
        return this.context
      }
    },
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      result({ data }) {
        this.name = data.adminAccount.acronym
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('dd-MM-yyyy')
    },
    statusCon(item) {
      return item.active ? 'success' : 'error'
    },
    textCon(item) {
      return item.active ? 'Ativo' : 'Inativo'
    },
    async changeStatusConnection(id, isInativate) {
      const confirmation = await this.$confirm({
        title: isInativate ? 'Inativar Conexão' : 'Ativar Conexeão',
        message: isInativate ? 'Deseja inativar a conexão?' : 'Deseja ativar a conexão?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        let variables = {
          connection_id: id,
          disable: false
        }
        if (isInativate) {
          variables.disable = true
        }
        await this.$apollo.mutate({
          mutation: MUTATION_CHANGE_STATUS_CONNECTION,
          variables,
          context: this.context
        })
        this.$alert({
          alert_message: 'Status da conexão alterado com sucesso!',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$apollo.queries.connections.refetch()
      } catch (error) {
        return this.$alert({
          alert_message: 'Falha ao inativar conexão',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async executeAction(data) {
      if (this.edit) {
        await this.editStorage(data)
      } else {
        await this.createStorage(data)
      }
    },
    async createStorage(data) {
      const confirmation = await this.$confirm({
        title: 'Criar Store',
        message: 'Deseja criar a store?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        let variables = {
          accountId: this.accountId,
          storageConfig: { ...data.json_gcs, storage_type: 'gcs' }
        }
        await this.$apollo.mutate({
          mutation: MUTATION_CREATE_STORAGE,
          variables,
          context: this.context
        })
        this.$alert({
          alert_message: 'Storage criado com sucesso!',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.dialog = false
        this.$apollo.queries.connections.refetch()
        this.$apollo.queries.storage.refetch()
      } catch (error) {
        return this.$alert({
          alert_message: 'Erro ao criar storage',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async editStorage(data) {
      const confirmation = await this.$confirm({
        title: 'Editar Store',
        message: 'Deseja editar a store?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      try {
        let variables = {
          accountId: this.accountId,
          storageConfig: { ...data.json_gcs, storage_type: 'gcs' }
        }
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_STORAGE,
          variables,
          context: this.context
        })
        this.$alert({
          alert_message: 'Storage editado com sucesso!',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.dialog = false
        this.$apollo.queries.connections.refetch()
        this.$apollo.queries.storage.refetch()
      } catch (error) {
        return this.$alert({
          alert_message: 'Erro ao editar storage',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    goToDetails(id) {
      this.$router.push(`/accounts/${this.accountId}/friday/editar/${id}`)
    },
    goToCreate() {
      this.$router.push(`/accounts/${this.accountId}/friday/criar`)
    }
  }
}
</script>
