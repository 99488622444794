<template>
  <mf-dialog v-model="value" max-width="800" title="Configuração Storage" persistent>
    <v-row class="px-3">
      <v-col>
        <v-row>
          <v-col>
            <label class="subheading">Json Storage</label>
            <v-jsoneditor v-model="jsonGcs" :plus="false" height="250px" :options="editorOptions" width="400px" @error="handleError" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="auto">
                <mf-button color="error" label="Sair" icon="restart_alt" @click="closeDialog"></mf-button>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row justify="end">
              <v-col cols="auto">
                <mf-button :disabled="disable" icon="send" label="Salvar" @click="clickButton"></mf-button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </mf-dialog>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    disableButton: {
      type: Boolean,
      default: false
    },
    gcs: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      jsonGcs: {},
      editorOptions: {
        mode: 'text',
        mainMenuBar: false
      },
      disable: this.disableButton
    }
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
    jsonGcs(val) {
      this.disable = val === ''
    },
    disableButton() {
      this.disable = this.disableButton
    }
  },
  mounted() {
    if (this.gcs) {
      this.jsonGcs = this.gcs
      delete this.jsonGcs.__typename
      for (const key in this.jsonGcs) {
        if (this.jsonGcs[key] === null) delete this.jsonGcs[key]
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
    },
    clickButton() {
      if (this.validJson()) {
        this.$emit('click-button', {
          json_gcs: this.jsonGcs
        })
      } else {
        return this.$alert({
          alert_message: 'Falha ao validar campos. Json fornecido não está seguindo o padrão do storage config.',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    handleError(error) {
      this.disable = error !== ''
    },
    validJson() {
      const validAtt = [
        'auth_provider_x509_cert_url',
        'auth_uri',
        'client_email',
        'client_id',
        'client_x509_cert_url',
        'private_key_id',
        'project_id',
        'token_uri',
        'type'
      ]
      const isValid = validAtt.filter(item => this.jsonGcs.hasOwnProperty(item)).length === 9
      return isValid
    }
  }
}
</script>
